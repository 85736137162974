@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .sectionSize {
      @apply lg:px-48 md:px-12 px-4 py-14 flex flex-col items-center;
    }
    .secondaryTitle {
      @apply  text-3xl bg-left-bottom bg-no-repeat pb-8 px-16 mb-8;
    }
  }
  {/*font-pt-serif*/}
  .growing-underline {
    display: block;
    position: relative;
    padding: 0.2em 0;
    overflow: hidden;
  }
  
  .growing-underline::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: black;
    transition: opacity 300ms, transform 300ms;
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
  
  .growing-underline:hover::after,
  .growing-underline:focus::after {
    transform: translate3d(0, 0, 0);
  }
  
